

.gitUser {
	width: 100px;
	border-radius: 50px;
}
.cardTitle {
	min-height: 60px;
}
.cardDesc {
	min-height: 60px;
}
.repoCard {
	cursor: pointer;
}
.repoCard:hover {
	background-color: var(--mui-palette-action-hover);
}
.issues {
	padding: 16px 0px;
	border-bottom: 1px solid lightgray;
}
.issueDetail {
	padding: 65px 16px 8px 16px;
}