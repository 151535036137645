:root {
  --risk-color-na: #666;
  --risk-color-ten: #27AE60;
  --risk-color-twenty: #8BC330;
  --risk-color-thirty: #D0DF1F;
  --risk-color-forty: #F2E14C;
  --risk-color-fifty: #F2C94C;
  --risk-color-sixty: #F2B04C;
  --risk-color-seventy: #F2994A;
  --risk-color-eighty: #F27C4A;
  --risk-color-ninety: #EB5757;
}
.na { color: var(--risk-color-na); }
.ten { color: var(--risk-color-ten); }
.twenty { color: var(--risk-color-twenty); }
.thirty { color: var(--risk-color-thirty); }
.forty { color: var(--risk-color-forty); }
.fifty { color: var(--risk-color-fifty); }
.sixty { color: var(--risk-color-sixty); }
.seventy { color: var(--risk-color-seventy); }
.eighty { color: var(--risk-color-eighty); }
.ninety { color: var(--risk-color-ninety); }

.na-bg { background-color: var(--risk-color-na) !important; }
.ten-bg { background-color: var(--risk-color-ten) !important; }
.twenty-bg { background-color: var(--risk-color-twenty) !important; }
.thirty-bg { background-color: var(--risk-color-thirty) !important; }
.forty-bg { background-color: var(--risk-color-forty) !important; }
.fifty-bg { background-color: var(--risk-color-fifty) !important; }
.sixty-bg { background-color: var(--risk-color-sixty) !important; }
.seventy-bg { background-color: var(--risk-color-seventy) !important; }
.eighty-bg { background-color: var(--risk-color-eighty) !important; }
.ninety-bg { background-color: var(--risk-color-ninety) !important; }

:root {
  --c-dark1: #33333F;
  --c-dark2: #1A2938;
  --c-blue-main: #56CCF2;
  --c-light: #BDBDBD;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background-color: var(--c-dark2);
    font-size: 14px;
    line-height: 14px;
}

.App {
}
h2 {
  color: #FFFFFF;
}
.tokenTitle {
  color: #999999;
  font-size: 10px;
  padding: 3px 5px;
}
.wrapper {
  display: flex;
}
.tokenLookup {
  margin-top: 40px !important;
  margin-bottom: 40px;
}
.tokenResearchWrapper {
  max-width: 100% !important;
}
.token {
  .address {
    text-align: right;
    a {
      color: lightblue;
    }
  }
}
.newAppBar {
  /*width: calc(100% - 180px)!important;
  margin-right: 7%;*/
}
.newToolBar {
  background: var(--c-dark1);
  min-height:36px !important;
}
.navList {
  padding-top: 0px !important;
}
.title {
  font-size: 9px;
  color: #ebebeb;
  flex-grow: 1;
}
.title span {
  color: #FFFFFF;
}
.logo{
  width: 100px;
  padding: 3px 20px 0px 0px;
}
.drawer {
  width: 180px;
  background: #4f4f4f;
}
.drawPaper {
  width: 180px;
  background: #4f4f4f !important;
}
.ListButton div {
  color: #ffffff;
}
.drawPaper li, .drawPaper li div {
  color: #ffffff;
}
.action {
  align: right;
}
.symbol {
  float: right;
}
.riskScore {
  text-align: center;
  font-weight: 800;
  color: #ffffff;
  border-radius: 6px;
  margin: 5px;
  padding: 10px 10px 20px 10px;
}
.riskScore-sm {
  text-align: center;
  font-weight: 800;
  color: #ffffff;
  border-radius: 6px;
  margin-left: 8px;
  padding: 2px 10px 2px 10px;
}
.riskScore .risk {
  color: var(--risk-color-ten);
}
.riskScoreNumber {
}
.riskScoreNumber h6 {
  text-align: left;
  font-weight: 200;
  font-size: 10px;
  color: #FFFFFF;
  margin: -5px 0px 10px 0px;
  line-height: 14px;
}
.riskScoreNumber span {
  line-height: 80px;
  font-size: 62px;
}
.textcon{
  font-size: 1em;
  vertical-align: text-bottom;
}

.purchase .dataBox {
  background: none;
  padding: 0px;
}
.pass {
  border-radius: 7px;
  float: right;
  background-color: var(--risk-color-ten);
  display: inline-block;
  width: 14px;
  Height: 14px;
}
.fail {
  border-radius: 7px;
  float: right;
  background-color: var(--risk-color-ninety);
  display: inline-block;
  width: 14px;
  Height: 14px;
}
hr.listLine {
  margin: 6px 0px;
}

button.prime {
  background-color: var(--risk-color-ten);
}
button.prime:hover {
  background-color: #06863C;
}
button.error {
  background-color: var(--risk-color-ninety);
}
button.error.MuiButton-outlined {
  background-color: #ffffff;
  border-color: var(--risk-color-ninety);
  color: var(--risk-color-ninety);
}
button.error.MuiButton-outlined:hover {
  background-color: var(--risk-color-ninety);
  color: #ffffff;
}

.error-warning {
  padding: 10px;
  color: #FFFFFF;
  border-radius: 12px;
  background: var(--risk-color-ninety);
}
.error-warning .main-heading {
  text-align: center;
}
.error-image {
  background: #FFF;
  text-align: center;
  border-radius: 12px;
  padding: 5px;
}
.error-code {
  color: #56CCF2;
}
.error-code h1 {
  font-size: 200px;
  margin-top: 50px;
  margin-bottom: 100px;
}

.MuiDataGrid-overlayWrapperInner {
  height: 8px !important;
}
.accordionDetail {
  color: #FFFFFF !important;
  background-color: #444444 !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}
.accordionDetail:hover {
  background-color: #4f4f4f !important;
}
.MuiAccordion-root:before {
  position: inherit !important;
}
.accordionTitle {
  padding: 0px !important;
  min-height: 0px !important;
}
.accordionTitle  .MuiAccordionSummary-content {
  margin: 0px;
}
.accordionDescription {
  padding: 8px 2px !important;
  color: #A7A7A7 !important;
}
.accordionDescription a{
  color: #56CCF2;
}
.accordionDetail span.accordionHeaderContents {
  width: 100%;
  padding: 2px;
}
.css-1wc0u5o-MuiPaper-root-MuiAccordion-root:before {
  background-color: none !important;
  
}

.verified {
  color: #8BC330;
}

.unverified {
  color: #EB5757;
}
.unverified .tokenContract {
  color: #ccc;
}
.unverified .tokenMainNet {
  color: #ccc;
}
.token-select {
  z-index: 10;
}
.tokenLabel {
  vertical-align: middle;
  font-weight: 600;
}
.tokenContract {
  margin-left: 10px;
  color: #888;
  vertical-align: middle; 
}
.tokenMainNet {
  margin-left: 10px;
  color: #888;
  vertical-align: middle;
}
.address-search input {
  color: #333;
  background-color: #fff;
  border-radius: 4px;
}
.fidCoin {
  padding: 6px 10px;
  margin: 0px 5px;
  border-radius: 6px;
  border: 1px solid grey;
  background: dimgrey;
}
.add-image {
  max-width: 200px;
  max-height: 150px;
  padding: 0px 16px;
}
.install {
  border-color: #fff !important;
  color: #fff !important;
}
.install:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.data-box-wrapper {
  .scores, .new-data {
      color: #ffffff;
      background: #4f4f4f;
      border-radius: 8px;
  }
  h2.wrapper-title {
      color: #FFFFFF;
      margin-top: 32px;
  }
}
.dataBox {
  margin: 5px;
  padding: 10px;
  background: #444444;
  border-radius: 4px;
  h6 {
    font-weight: 200;
    font-size: 10px;
    color: #999999;
    margin: -5px 0px 10px 0px;
  }
  h5 {
    color: #999999;
    margin: -5px 0px 10px 0px;
  }
  .large {
    font-weight: 700;
    font-size: 24px;
    margin: 15px 0px;
    display: inline-block;
  }
  .bar {
    display: inline-block;
    height: 36px;
  }
  .first {
    background-color: royalblue;
    width: 56%;
  }
  .second {
    background-color: skyblue;
    width: 24%;
  }
  .third {
    background-color: cyan;
    width: 12%;
  }
  .fourth {
    background-color: lightblue;
    width: 8%;
    border-radius: 0px 8px 8px 0px;
  }
}
.hidden {
  display: none;
}
.charting {

}
.social-image {
  margin: 10px 10px 0px 0px;
}
.social-image.grey {
  filter: grayscale(100%);
}
p.tokenDescription {
  line-height: 1.6em;
}

.hal-report {
  margin: 40px 0px 0px 0px;
  padding: 20px;
  margin-left: 0px !important;
}
.hal-box {
  border-radius: 8px;
  background-color: #4f4f4f;
  padding: 16px;
}
.hal-box p {
  color: #FFFFFF;
  line-height: 1;
}
.hal-box p span {
  float: right;
}
.hal-score {
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
  text-align: center;
  padding: 16px;
  color: #FFF;
  border-radius: 8px;
  background-color: #D0DF1F;
}
.hal-score-block {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  padding: 4px 24px;
  color: #FFF;
  border-radius: 8px;
  background-color: #D0DF1F;
}
.hal-content {
  margin: 8px;
  background-color:#cccccc;
  border-radius: 8px;
  font-family: monospace;
}
.hal-content div div {
  padding: 8px;
}
.hal-content-wrapper {
  display: inline-block;
}
.hal-content div div p {
  font-family: monospace;
}
h2.hal-contract {
  color: #000;
  font-size: 16px;
}
.hal-select {
  text-align: right;
}
.hal-change {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #FFF;
  padding: 8px;
  text-align: center;
}
.hal-block-button {
  color: #333 !important;
  width: 12.5%;
  border: 1px solid #333 !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
}
.hal-row {
}
.hal-select-buttons {
  padding: 8px 8px 8px 39px;
}
.hal-left {
  text-align: left;
}
.hal-right {
  text-align: right;
}
.hal-plus {
  color: #FFF;
  padding: 4px;
  background-color: #336633;
  display: block;
  border-radius: 4px;
}
.hal-minus {
  color: #FFF;
  padding: 4px;
  background-color: #993333;
  display: block;
  border-radius: 4px;
}
button.hal-tab {
  margin: 4px;
  padding: 4px;
  font-weight: 700;
  color: #4f4f4f;
}
button.hal-tab.Mui-selected {
  color: #FFF;
}
.tabpanel {
  background-color: #4f4f4f;
  border-radius: 8px;
}
.MuiChartsAxis-line, .MuiChartsAxis-tick {
  stroke: #FFF !important;
}
.MuiChartsAxis-tickLabel {
  fill: #FFF !important;
}




.auditBlock {
  display: inline-block;
  cursor: pointer;
  border-radius: 8px;
  float: left;
  margin-right: 16px;
  padding: 16px;
  text-align: center;
  h4 {
    margin: 8px 4px;
  }
  h3 {
    margin: 0px;
  }
}
.auditBlock:hover {
  background-color: #444;
}
.auditBlock.selected {
  background-color: #666;
}
.auditBody {
  margin: 40px 0px 0px 0px;
  padding: 20px;
  margin-left: 0px !important;
  color: #ebebeb;
  a {
    color: var(--c-blue-main);
  }
  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid #666;
  }
  hr.sub {
    width: 100%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  }
}
.tabs {
  width: 100%;
}
ul.tab-list {
  list-style: none;
  padding-inline-start: 0px;
  width: 100%;
  display: inline-block;
  .tab-btn {
    float: left;
    padding: 8px 6px;
    border-radius: 4px;
  }
  .tab-btn:hover {
    cursor: pointer;
    background: var(--c-dark1);
  }
  .tab-btn--active {
    background: var(--c-dark1);
    color: var(--c-blue-main);
  }
}
.tab-panel {
  padding: 16px;
  display: inline-block;
  .field {
    padding: 4px 8px 4px 4px;
    text-align: right;
    color: #56ccf2;
    font-weight: bold;
  }
  .field.inner {
    text-align: right;
  }
  .data {
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #FFF;
  }
  .data.contract {
    font-family: courier;
  }
  .split {
    border-radius: 8px;
    Background: var(--c-dark2);
    color: #56ccf2;
    margin: 10px;
    h4 {
      border-radius: 8px 8px 0px 0px;
      background-color: white;
      text-align: center;
      margin: 0px;
      padding: 12px;
      color: black;
    }
    p {
      font-weight: bold;
      padding: 16px 8px 8px 8px;
      margin: 0px;
      span {
        font-weight: normal;
        color: white;
      }
    }
  }
  .header {
    text-align: right;
    color: var(--c-blue-main);
    font-weight: bold;
    padding: 8px;
    font-size: 1.5em;
  }
  .contentBox {
    border-radius: 8px;
    background: var(--c-dark1);
    padding: 8px;
  }
  .spacer {
    padding: 8px;
  }
}
.login {
  padding-left: 0px;
  padding-right: 0px;
  .logo {
    padding: 8px;
  }
  h2 {
    color: var(--c-blue-main);
  }
  .left {
    background: white;
    .box {
      padding: 0px 15%;
      .card {
        margin: 40px 0px;
      }
    }
  }
  .right {
    background: var(--c-dark2);
    .header {
      padding: 32px;
      p {
        color: white;
      }
    }
    .banner {
      width: 80%;
      margin: 5% 10%;
      border-radius: 8px;
      box-shadow: 0 0 20px #56ccf299;
    }
  }
  .altSign {
    display: inline-block;
  }
  .options {
    align: center;
  }
}
.signup {
  h2 {
    color: var(--c-blue-main);
  }
  .header {
    padding: 8px 16px;
  }
  .helper {
    color: darkgrey;
    margin-top: 0px;
  }
  .logo {
    width: 200px;
    padding: 32px 8px;
  }
}